import React, { Component }  from 'react'
import moment from 'moment'
import axios from 'axios'
import styled from 'styled-components'
import SortableTable from './tables/SortableTable.js'
import MonitoringRequestFilters from './filters/MonitoringRequestFilters'
import MonitoringRequestForm from './MonitoringRequestForm.js'
import MonitoringRequestInfo from './details/MonitoringRequestInfo.js'
import { appConstants, setRequest } from 'utils/constants.js'
import I18n from 'i18n-js/locales.js'
import { CSVLink } from 'react-csv'
import Modal from 'react-bootstrap/Modal'
import Collapse from 'react-bootstrap/Collapse'
import { BsBoxArrowDown, BsBoxArrowUp } from 'react-icons/bs'
import Fuse from 'fuse.js'


const FiltersButton = styled.div`
  left: .5em;
  top: .1em;
  position: absolute;
  z-index: 0;
  cursor: pointer;
  color: #205492;
  `;

const ExportWrapper = styled(CSVLink)`
  left: 88%;
  top: -6px;
  position: relative;
  z-index: 0;
  `;

const searchOptions = {
  // https://fusejs.io/api/options.html for full list of options
  minMatchCharLength: 3,
  threshold: 0.1,
  distance: 500,
  keys: [
    "name",
    "id",
    "description",
    "beneficiaries",
    "request_justification_details",
    "poc",
    "location"
  ]
};

moment.updateLocale('en', {
  week: {
      dow: 5,
      doy: 1,
  },
});

export default class TPMArchive extends Component {

  state = {
    initialData: this.props.initialData.data || [],
    filteredData: this.props.initialData.data || [],
    filters: this.props.initialData.filters || [],
    tableColumns: this.props.initialData.columns || [],
    topLevelColumns: this.props.initialData.top_level_columns || [],
    viewModal: false,
    selectedRequest: {},
    showFilters: true,
    searchTerms: "",
  }


  refreshData = () => {
    axios.get(appConstants.ARCHIVE_URL)
    .then(res => {
      const monitoringRequests = res.data;
      this.setState({
        initialData: monitoringRequests.data,
      }, () => {
        this.filterData(this.state.filters)
      })
    })
    .catch(error => {
      console.log(error);
    })
  }

  // filtering functions
  handleFilterChange = (e, filterBy) => {
    const filters = this.state.filters
    let filterValue = null
    if (e) { filterValue = e.value } 
    const newFilters = {
      ...filters,
      [filterBy]: filterValue,
    }
    this.filterData(newFilters)
  }

  handleSearch = (e) => {
    this.setState({
      searchTerms: e.target.value
    }, () => {
        this.filterData(this.state.filters)
    })
  }

  filterData = (filters) => {
    const data = this.state.initialData
    const filterKeys = Object.keys(filters)
    let filteredData = [...data]
    for (let filterKey of filterKeys) {
      if (filters[filterKey] !== null) {
        if (filters[filterKey] == -1) {
          filteredData = filteredData.filter(task => (task[filterKey] == "" || task[filterKey] == null) )
        } else {
          filteredData = filteredData.filter(task => task[filterKey] == filters[filterKey] )
        }
        
      }
    }
    if (this.state.searchTerms.length > 2) {
      const fuse = new Fuse(filteredData, searchOptions);
      const searchResults = fuse.search(this.state.searchTerms);
      filteredData = searchResults.map(task => task.item)
    }
    
    const newState = {
      ...this.state,
      filteredData: filteredData,
      filters: filters,
    }
    this.setState(newState)
  }


  requestModalLink = (cell, row) => {
    return (
      cell["column"]["id"] === "name"
        ? <a href='#' onClick={() => {this.editRequest(row.original)}}>{row["original"]["name"]}</a>
        : cell.render('Cell') 
    )
  }

  // modal/form functions
  editRequest = (task) => {
    const request = setRequest(task)
    this.setState({
      selectedRequest: request,
      viewModal: true
    })
  }

  closeModal = () => {
    this.setState({
      viewModal: false
    })
  }

  renderMonitoringRequestModalContent() {
    return this.props.userRole === 'read_only' ? (
      <MonitoringRequestInfo col={6}
        monitoringRequestId={this.state.selectedRequest.id} />
    ) : (
      <MonitoringRequestForm col={6}
        monitoring_request={this.state.selectedRequest}
        authenticityToken={this.props.authenticityToken}
        userRole={this.props.userRole}
        refreshData={this.refreshData}
        closeModal={this.closeModal} />
    )
  }

  renderRequestModal() {
    return (
      <Modal
        show={this.state.viewModal}
        onHide={this.closeModal}
        keyboard={true}
        size={'xl'}>
        <Modal.Header closeButton>
        <Modal.Title>{I18n.t('activerecord.attributes.monitoring_request.monitoring_event')} {this.state.selectedRequest.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderMonitoringRequestModalContent()}
        </Modal.Body>
      </Modal>
    )
  }

  renderFiltersButton() {
    return (
      <FiltersButton 
        onClick={() => this.setState({showFilters: !this.state.showFilters})} 
        aria-controls="collapse-filters" 
        aria-expanded={this.state.showFilters}>
          <h4>{this.state.showFilters ? <BsBoxArrowUp /> : <BsBoxArrowDown />}</h4>
      </FiltersButton>
    ) 
  }

  renderList() {
    return (
      <SortableTable
        data={this.state.filteredData}
        columns={this.state.tableColumns}
        useRenderFunction={true}
        cellContent={this.requestModalLink}
      />  
    ) 
  }

  render() {
    return (
      <>
        {this.renderRequestModal()}
        <div>
          <Collapse in={this.state.showFilters}>
            <div id="collapse-filters">
              <MonitoringRequestFilters handleFilterChange={this.handleFilterChange} handleSearch={this.handleSearch} includedFilters={this.state.filters} />
            </div>
          </Collapse>
          {this.renderFiltersButton()}
        </div>

        <div>
          <ExportWrapper  data={this.state.filteredData}>{I18n.t("headers.monitoring_request.export")}</ExportWrapper>
          {this.renderList()}
        </div>
      </>
    )
  }
}

