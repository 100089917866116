import React from 'react'
import I18n from 'i18n-js/locales.js'
import { appConstants } from 'utils/constants.js'
import moment from 'moment'
import Gallery from './Gallery.js'
import { Container, Row, Col } from 'react-bootstrap'
import ReactJson from 'react-json-view'



export default function Verification(props) {
  return (
    <Container fluid>
      <Row>
        <Col md={6}>
          <br/>
          <p><strong>{props.verification.form_name} - {props.verification.subject_id}</strong></p>
          <p><strong>{I18n.t('activerecord.attributes.verification.verification_date')}</strong>: {props.verification.verification_date ? moment(props.verification.verification_date).format('MM-DD-YYYY') : ""}</p>
          <p><strong>{I18n.t('activerecord.attributes.verification.visit_id')}</strong>: {props.verification.visit_id}</p>
          <p><strong>{I18n.t('activerecord.attributes.verification.form_id')}</strong>: {props.verification.form_id}</p>
          <p><strong>{I18n.t('activerecord.attributes.verification.interviewer_id')}</strong>: {props.verification.interviewer_id}</p>
          <p><strong>{I18n.t('activerecord.attributes.verification.details')}</strong>:</p>
          <ReactJson src={props.verification.details} collapsed={true} />
        </Col>
        <Col md={6}>
          <Gallery
            url={appConstants.VERIFICATION_IMAGES_URL + String(props.verification.id)}
            title={I18n.t('activerecord.attributes.monitoring_request.verification_images')} />
        </Col>
      </Row>
    </Container>
  )
}
