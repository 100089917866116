import React, { useEffect, useState }  from "react"
import axios from 'axios'
import Select from 'react-select'

export default function SelectMultiple(props) {
  const [options, setOptions] = useState([props.selected])

  const loadOptions = async () => {
    const res = await axios.get(props.url)
    setOptions(res.data.map(i => ({value: i.id, label: i.name})))
  }

  useEffect(() => {
    loadOptions(props.selected)
  }, [props.selected])

  return (
    <Select 
        isMulti={true}
        isSearchable={true}
        closeMenuOnSelect={false}
        value={props.selected.map(i => options.find(obj => obj.value === i))} 
        onChange={props.onChange} 
        options={options}
        placeholder={props.placeholder} />
  );
}