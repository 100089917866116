import React, { useEffect, useState }  from "react"
import ListGroup from 'react-bootstrap/ListGroup'
import axios from 'axios'

export default function ResourceList(props) {
  const [values, setValues] = useState([])

  const getValues = async () => {
    const res = await axios.get(`/kmp/search/${props.target}?tag_ids=${props.tagIds.join()}&search_terms=${props.searchTerms}`)
    setValues(res.data)
  }

  useEffect(() => {
    getValues()
  }, [props.searchTerms, props.tagIds, props.implementingMechanismId])

  return (
    <ListGroup variant="flush" className="border-light">
      { values.map(v =>  <ListGroup.Item key={v.id}><strong><a href='#' onClick={() => {props.setResource(v)}}>{v.name}</a></strong><br/><small>{v.description}</small></ListGroup.Item>)}
    </ListGroup>
  )
}
