import React, { Component }  from 'react'
import moment from 'moment'
import axios from 'axios'
import styled from 'styled-components'
import Board from './boards/Board'
import SortableTable from './tables/SortableTable.js'
import EventMap from './maps/EventMap.js'
import MonitoringRequestFilters from './filters/MonitoringRequestFilters'
import FilterCounts from './filters/FilterCounts'
import MonitoringRequestForm from './MonitoringRequestForm.js'
import MonitoringRequestInfo from './details/MonitoringRequestInfo.js'
import { appConstants, setRequest, renderTimeDots, dateFields, taskHeight } from 'utils/constants.js'
import I18n from 'i18n-js/locales.js'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { CSVLink } from 'react-csv'
import Form from 'react-bootstrap/Form'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Collapse from 'react-bootstrap/Collapse'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { BsBoxArrowDown, BsBoxArrowUp } from 'react-icons/bs'
import Fuse from 'fuse.js'



const NewRequestButton = styled(Button)`
  right: 1.5em;
  position: absolute;
  z-index: 0;
  `;

const FiltersButton = styled.div`
  left: .5em;
  top: .1em;
  position: absolute;
  z-index: 0;
  cursor: pointer;
  color: #205492;
  `;

const Disclaimer = styled.div`
  float: right;
  position: relative;
  z-index: 0;
  color: red;
  font-size: small;
  `;


const ExportWrapper = styled(CSVLink)`
  left: 88%;
  top: -6px;
  position: relative;
  z-index: 0;
  `;

const MapOptions = styled.div`
  right: .5em;
  position: absolute;
  z-index: 500;
  background-color: gainsboro;
  opacity: .8;
  color:black;
  margin: 1em;
  border-radius: .5em;
  padding: .5em;
  font-size: small;
  `;

const searchOptions = {
  // https://fusejs.io/api/options.html for full list of options
  minMatchCharLength: 3,
  threshold: 0.1,
  distance: 300,
  keys: [
    "name",
    "id",
    "description",
    "beneficiaries",
    "request_justification_details",
    "poc",
  ]
}

export default class TPMDashboard extends Component {

  state = {
    initialData: this.props.initialData.data || [],
    filteredData: this.props.initialData.data || [],
    filters: this.props.initialData.filters,
    tableColumns: this.props.initialData.columns,
    reviewBoard: this.props.initialData.review_board,
    selectionBoard: this.props.initialData.selection_board,
    viewModal: false,
    selectedRequest: {},
    selectedTab: "map",
    showFilters: true,
    searchTerms: "",
    mapClusters: true,
  }

  componentDidMount() {
    const reviewBoard = this.setBoardData(this.state.reviewBoard, this.state.initialData)
    const selectionBoard = this.setBoardData(this.state.selectionBoard, this.state.initialData)
    const newState = {
      ...this.state,
      reviewBoard: reviewBoard,
      selectionBoard: selectionBoard,
    }
    this.setState(newState)
  }

  refreshData = () => {
    axios.get(appConstants.PLANNING_URL)
    .then(res => {
      const monitoringRequests = res.data;
      this.setState({
        initialData: monitoringRequests.data,
      }, () => {
        this.filterData(this.state.filters)
      })
    })
    .catch(error => {
      console.log(error);
    })
  }

  // filtering functions
  handleFilterChange = (e, filterBy, clickClear) => {
    const filters = this.state.filters
    let filterValue = null
    if (e && (this.state.filters[filterBy] != e.value || !clickClear)) { filterValue = e.value } 
    const newFilters = {
      ...filters,
      [filterBy]: filterValue,
    }
    this.filterData(newFilters)
  }

  handleSearch = (e) => {
    this.setState({
      searchTerms: e.target.value
    }, () => {
        this.filterData(this.state.filters)
    })
  }

  handleMapClusterChange = () => {
    this.setState(prevState => ({
      mapClusters: !prevState.mapClusters
    }));
  }

  filterData = (filters) => {
    const data = this.state.initialData
    const filterKeys = Object.keys(filters)
    let filteredData = [...data]
    for (let filterKey of filterKeys) {
      if (filters[filterKey]) {
        if (filters[filterKey] == -1) {
          filteredData = filteredData.filter(task => (task[filterKey] == "" || task[filterKey] == null) )
        } else {
          filteredData = filteredData.filter(task => task[filterKey] == filters[filterKey] )
        }
        
      }
    }
    if (this.state.searchTerms.length > 2) {
      const fuse = new Fuse(filteredData, searchOptions);
      const searchResults = fuse.search(this.state.searchTerms);
      filteredData = searchResults.map(task => task.item)
    }

    const reviewBoard = this.setBoardData(this.state.reviewBoard, filteredData)
    const selectionBoard = this.setBoardData(this.state.selectionBoard, filteredData)
    const newState = {
      ...this.state,
      filteredData: filteredData,
      reviewBoard: reviewBoard,
      selectionBoard: selectionBoard,
      filters: filters,
    }
    this.setState(newState)
  }

  //  tpm board functions
  setBoardData = (board, data) => {
    const columnKeys = Object.keys(board.columns)
    let newColumns = board.columns
    let newTasks = {}
    for (let key of columnKeys) {
      newColumns[key]["taskIds"] = []
    }
    data.map(task => {
      if (newColumns[task.request_status_id]) {
        task.id = task.id.toString()
        newTasks[task.id] = task
        newColumns[task.request_status_id]["taskIds"].push(task.id)
      }
    })
 
    const newBoard = {
      ...board,
      tasks: newTasks,
      columns: newColumns,
    }
    return newBoard
  }

  dropDisabled = (index, columnId, homeIndex, multidirectional) => {
    if (multidirectional && this.props.userRole !== 'read_only') {
      return false
    } else if (this.props.userRole === 'read_only') {
      return true
    } else {
      return index < homeIndex
    }
  }

  taskContent = (task) => {
    const lastStatus = task.request_status_id == 'submitted' ? I18n.t('activerecord.attributes.monitoring_request.date_submitted') : 'Status Updated'
    return (
      <div>
        <strong><a href='#' onClick={() => {this.editRequest(task)}}>{task.id}. {task.name}</a></strong> <br/>
        {I18n.t('activerecord.attributes.monitoring_request.date_requested')}: {task.date_requested} <br/>
        {I18n.t('activerecord.attributes.monitoring_request.start_date')}: {task.start_date} <br/>
        {lastStatus}: {task.date_submitted} <br/>
        {I18n.t('activerecord.attributes.monitoring_request.ip')}: {task.implementing_partner_name} <br/>
        {I18n.t('activerecord.attributes.monitoring_request.governorate')}: {task.governorate_name} <br/>
        {I18n.t('activerecord.attributes.monitoring_request.district')}: {task.district_name} <br/>
        {renderTimeDots(task.end_date, task.request_status_id)}
      </div>
    )
  }

  requestModalLink = (cell, row) => {
    return (
      cell["column"]["id"] === "name"
        ? <a href='#' onClick={() => {this.editRequest(row.original)}}>{row["original"]["name"]}</a>
        : cell.render('Cell') 
    )
  }

  persistStatus(id, status) {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    const putBody = {"request_status": status, "date_submitted": moment()}
    putBody[dateFields[status]] = moment()
    axios.put(`${appConstants.MONITORING_EVENT_URL}${id}`, putBody )
    .then(res => {
      this.refreshData()
    })
    .catch(error => {
      console.log(error)
    })
  }

  onReviewDragStart = start => {
    this.onDragStart(start,"reviewBoard")
  }

  onSelectionDragStart = start => {
    this.onDragStart(start,"selectionBoard")
  }

  onReviewDragEnd = result => {
    this.onDragEnd(result,"reviewBoard")
  }

  onSelectionDragEnd = result => {
    this.onDragEnd(result,"selectionBoard")
  }

  onDragStart = (start, board) => {
    const homeIndex = this.state[board].columnOrder.indexOf(start.source.droppableId);
  
    const draggingState = {
      ...this.state[board],
      homeIndex: homeIndex
    }
    this.setState({[board]: draggingState})
  }

  onDragEnd = (result, board) => {
    const droppedState = {
      ...this.state[board],
      homeIndex: null
    }
    this.setState({[board]: droppedState})

    const { destination, source, draggableId } = result;

    // Moving aborted
    if(!destination) {
      return;
    }

    //Moving to original spot
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const start = this.state[board].columns[source.droppableId]
    const finish = this.state[board].columns[destination.droppableId]

    // Moving within same column 
    if(start === finish) {
      const newTaskIds = Array.from(start.taskIds)
      newTaskIds.splice(source.index, 1)
      newTaskIds.splice(destination.index, 0, draggableId)

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };
    
      const newState = {
        ...this.state[board],
        columns: {
          ...this.state[board].columns,
          [newColumn.id]: newColumn,
        },
      };
    
      this.setState({[board]: newState})
      return
    }

    // Moving from one list to another
    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...this.state[board],
      columns: {
        ...this.state[board].columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    this.setState({[board]: newState})
    this.persistStatus(draggableId, destination.droppableId)
  }

  // tpm calendar functions
  getEventColor = (e) => {
    const style = {
      backgroundColor: e.hold ? '#c13851' : ''
    }
    return {
      style: style
    }
  }

  // modal/form functions
  editRequest = (task) => {
    const request = setRequest(task)
    this.setState({
      selectedRequest: request,
      viewModal: true
    })
  }

  newRequest = () => {
    this.setState({
    viewModal: true,
    selectedRequest: {}
    })
  }

  closeModal = () => {
    this.setState({
      viewModal: false
    })
  }

  renderMonitoringRequestModalContent() {
    return this.props.userRole === 'read_only' ? (
      <MonitoringRequestInfo col={6}
        monitoringRequestId={this.state.selectedRequest.id} />
    ) : (
      <MonitoringRequestForm col={6}
        monitoring_request={this.state.selectedRequest}
        authenticityToken={this.props.authenticityToken}
        userRole={this.props.userRole}
        refreshData={this.refreshData}
        closeModal={this.closeModal} />
    )
  }

  renderRequestModal() {
    return (
      <Modal
        show={this.state.viewModal}
        onHide={this.closeModal}
        keyboard={true}
        size={'xl'}>
        <Modal.Header closeButton>
        <Modal.Title>{`${I18n.t('activerecord.attributes.monitoring_request.monitoring_event')} ${this.state.selectedRequest.id ? this.state.selectedRequest.id : ""}` }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderMonitoringRequestModalContent()}
        </Modal.Body>
      </Modal>
    )
  }

  minHeight(board) {
    const maxTaskCount = board.columnOrder.map(c => board.columns[c].taskIds.length).sort((a,b) => b - a)[0]
    return `${maxTaskCount*taskHeight}em`
  }

  renderNewRequestButton() {
    return (this.props.userRole !== 'read_only') ?
    (
      <NewRequestButton variant="outline-success" size="sm" onClick={() => this.newRequest()}>{I18n.t('monitoring_request.new')}</NewRequestButton>
    ) : ""
  }

  renderFiltersButton() {
    return (
      <FiltersButton 
        onClick={() => this.setState({showFilters: !this.state.showFilters})} 
        aria-controls="collapse-filters" 
        aria-expanded={this.state.showFilters}>
          <h4>{this.state.showFilters ? <BsBoxArrowUp /> : <BsBoxArrowDown />}</h4>
      </FiltersButton>
    ) 
  }

  renderSelectionBoard() {
    return (this.state.selectedTab == "selection_board") ?
      (
        <Board 
          onDragStart={this.onSelectionDragStart}
          onDragEnd={this.onSelectionDragEnd}
          dropDisabled={this.dropDisabled}
          taskContent={this.taskContent}
          initialData={this.state.selectionBoard}
          minHeight={this.minHeight(this.state.selectionBoard)}
        />
      ) : ""
  }

  renderReviewBoard() {
    return (this.state.selectedTab == "board") ?
      (
        <Board 
          onDragStart={this.onReviewDragStart}
          onDragEnd={this.onReviewDragEnd}
          dropDisabled={this.dropDisabled}
          taskContent={this.taskContent}
          initialData={this.state.reviewBoard}
          minHeight={this.minHeight(this.state.reviewBoard)}
        />
      ) : ""
  }

  renderDashboard() {
    return (this.state.selectedTab == "map") ?
      (
      <>
        <Row>
          <Col md={3}>
            <FilterCounts
              filter_id={"request_status_id"}
              data={this.state.filteredData}
              url={appConstants.EVENT_STATUSES_URL}
              handleFilterChange={this.handleFilterChange}
              filters={this.state.filters}
              title={I18n.t('activerecord.attributes.monitoring_request.tpm_by_status')}
            />
            <FilterCounts
              filter_id={"governorate_id"}
              data={this.state.filteredData}
              url={appConstants.GOVERNORATES_URL}
              handleFilterChange={this.handleFilterChange}
              filters={this.state.filters}
              title={I18n.t('activerecord.attributes.monitoring_request.tpm_by_governorate')}
            />
          </Col>
          <Col md={6}>
            <Form>
              <MapOptions>
                <Form.Check
                  inline
                  label={I18n.t('activerecord.attributes.monitoring_request.show_map_clusters')}
                  type="checkbox"
                  name="clusters"
                  checked={this.state.mapClusters}
                  value={true}
                  onChange={this.handleMapClusterChange}
                  id="1" />
              </MapOptions>
            </Form>
            {this.renderMap()}
          </Col>
          <Col md={3}>
            <FilterCounts
              filter_id={"implementing_partner_id"}
              data={this.state.filteredData}
              url={appConstants.IPS_URL}
              handleFilterChange={this.handleFilterChange}
              filters={this.state.filters}
              title={I18n.t('activerecord.attributes.monitoring_request.tpm_by_ip')}
              omit_empty={true}
            />
          </Col>
        </Row>
      </>
      ) : ""
  }

  renderList() {
    return (this.state.selectedTab == "list") ?
      (
        <SortableTable
          data={this.state.filteredData}
          columns={this.state.tableColumns}
          useRenderFunction={true}
          cellContent={this.requestModalLink}
        />
      ) : ""
  }

  renderMap() {
    return (
        <EventMap
          data={this.state.filteredData}
          markerContent={this.taskContent}
          url={appConstants.EVENT_STATUSES_URL}
          filter_id={"request_status_id"}
          show_clusters={this.state.mapClusters}
        />) 
  }

  renderCalendar() {
    return (this.state.selectedTab == "calendar") ?
      (
        <Calendar
          localizer={momentLocalizer(moment)}
          events={this.state.filteredData}
          startAccessor="unformatted_start_date"
          endAccessor="unformatted_end_date"
          drillDownView="agenda"
          onSelectEvent={this.editRequest}
          style={{ height: 500 }}
          popup={true}
          eventPropGetter={this.getEventColor}
          step={360}
          timeslots={24}
          defaultView={'week'}
        />
      ) : ""
  }

  render() {
    return (
      <>
        {this.renderRequestModal()}
        <div>
          <Collapse in={this.state.showFilters}>
            <div id="collapse-filters">
              <MonitoringRequestFilters handleFilterChange={this.handleFilterChange} handleSearch={this.handleSearch} includedFilters={this.state.filters} />
            </div>
          </Collapse>
          {this.renderFiltersButton()}
        </div>
        <div>
          {this.renderNewRequestButton()}
          <Tabs defaultActiveKey="map" id="listTabs" onSelect={(selectedKey) => this.setState({selectedTab: selectedKey})}>
            <Tab eventKey="map" title={I18n.t("headers.monitoring_request.map")}>
              <br/>
              {this.renderDashboard()}
            </Tab>
            <Tab eventKey="list" title={I18n.t("headers.monitoring_request.list")}>
              <br/>
              <ExportWrapper  data={this.state.filteredData}>{I18n.t("headers.monitoring_request.export")}</ExportWrapper>
              {this.renderList()}
            </Tab>
            <Tab eventKey="selection_board" title={I18n.t("headers.monitoring_request.selection_board")}>
              <br/>
              {this.renderSelectionBoard()}
            </Tab>
            <Tab eventKey="board" title={I18n.t("headers.monitoring_request.board")}>
              <br/>
              {this.renderReviewBoard()}
            </Tab>
            <Tab eventKey="calendar" title={I18n.t("headers.monitoring_request.calendar")}>
              <br/>
              {this.renderCalendar()}
            </Tab>
          </Tabs>
        </div>
      </>
    )
  }
}

