import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import I18n from 'i18n-js/locales.js'
import SelectSingle from '../selects/SelectSingle.js'
import { appConstants } from 'utils/constants.js'

export default function MonitoringRequestFilters(props) {

  const renderActivity = () => {
    return (
      "activity_id" in props.includedFilters
      ?  <Col className="p-2 mr-3">
          <Form.Group controlId="activity">
            <SelectSingle
              selected={parseInt(props.includedFilters["activity_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "activity_id")}
              url={appConstants.ACTIVITIES_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.activity')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderIP = () => {
    return (
      "implementing_partner_id" in props.includedFilters
      ?  <Col className="p-2 mr-3">
          <Form.Group controlId="implementing_partner">
            <SelectSingle
              selected={parseInt(props.includedFilters["implementing_partner_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "implementing_partner_id")}
              url={appConstants.IPS_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.ip')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderGovernorate = () => {
    return (
      "governorate_id" in props.includedFilters
      ?  <Col className="p-2 ml-3 mr-3">
          <Form.Group controlId="governorate">
            <SelectSingle
              selected={parseInt(props.includedFilters["governorate_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "governorate_id")}
              url={appConstants.GOVERNORATES_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.governorate')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderMonitor = () => {
    return (
      "user_id" in props.includedFilters
      ?  <Col className="p-2 mr-3">
          <Form.Group controlId="user">
            <SelectSingle
              selected={parseInt(props.includedFilters["user_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "user_id")}
              url={appConstants.MONITORS_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.user')} 
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderReviewer = () => {
    return (
      "reviewer_id" in props.includedFilters
      ?  <Col className="p-2 mr-3">
          <Form.Group controlId="reviewer">
            <SelectSingle
              selected={parseInt(props.includedFilters["reviewer_id"])}
              onValueChange={(e) => props.handleFilterChange(e, "reviewer_id")}
              url={appConstants.REVIEWERS_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.reviewer')}
              isClearable={true}
              isFilter={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderStartWeek = () => {
    return (
      "start_week" in props.includedFilters
      ?  <Col className="p-2 mr-3">
          <Form.Group controlId="start_week">
            <SelectSingle
              selected={props.includedFilters["start_week"]}
              onValueChange={(e) => props.handleFilterChange(e, "start_week")}
              url={appConstants.TIME_INTERVALS_URL}
              placeholder={I18n.t('selects.week')}
              isClearable={true}
              isFilter={true}  />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderStatus = () => {
    return (
      "request_status_id" in props.includedFilters
      ?  <Col className="p-2 mr-3">
          <Form.Group controlId="request_status">
            <SelectSingle
              selected={props.includedFilters["request_status_id"]}
              onValueChange={(e) => props.handleFilterChange(e, "request_status_id")}
              url={appConstants.EVENT_STATUSES_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.top_level_status')}
              isClearable={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderMode = () => {
    return (
      "virtual" in props.includedFilters
      ?  <Col className="p-2 mr-3">
          <Form.Group controlId="virtual">
            <SelectSingle
              onValueChange={(e) => props.handleFilterChange(e, "virtual")}
              url={appConstants.EVENT_MODES_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.mode')}
              isClearable={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderProjectYear = () => {
    return (
      "project_year_id" in props.includedFilters
      ?  <Col className="p-2 mr-3">
          <Form.Group controlId="project_year_id">
            <SelectSingle
              selected={props.includedFilters["project_year_id"]}
              onValueChange={(e) => props.handleFilterChange(e, "project_year_id")}
              url={appConstants.PROJECT_YEARS_URL}
              placeholder={I18n.t('activerecord.attributes.monitoring_request.project_year')}
              isClearable={true} />
          </Form.Group>
        </Col>
      : ""
    )
  }

  const renderSearch = () => {
    return (
      !props.excludeSearch
      ?   <Row >
            <Col className="mr-2 ml-2 pb-2">
              <Form.Control type="text" placeholder={I18n.t("headers.monitoring_request.search")} onChange={(e) => props.handleSearch(e)} />
            </Col>
          </Row>
      : ""
    )
  }


  return (
      <Form  className="border rounded bg-light mb-2">
        <Row >
          {renderGovernorate()}
          {renderActivity()}
          {renderIP()}
          {renderMonitor()}
          {renderReviewer()}
          {renderStartWeek()}
          {renderStatus()}
          {renderMode()}
          {renderProjectYear()}
        </Row>
        {renderSearch()}
      </Form>
  )
}
