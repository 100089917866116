import React, { useEffect, useState }  from "react";
import axios from 'axios'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster';
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import styled from 'styled-components'
import { chartColors } from 'utils/constants.js'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@fortawesome/fontawesome-free/js/all.min.js'

const centralAfrica = [-1.8, 19]

const StyledMapContainer = styled(MapContainer)`
  height: 700px;
  `;

const LegendColorBox = styled.div`
  float: left;
  height: 1em;
  width: 1em;
  margin-top: 2px;
  border: 1px solid black;
  clear: both;
  `;

const Legend = styled.div`
  opacity: .8;
  background-color: gainsboro;
  color:black;
  height: auto;
  width: auto;
  margin: 1em;
  border-radius: .5em;
  position: absolute;
  padding: .5em;
  bottom: 1.5em;
  `;


export default function EventMap(props) {
  
  const [values, setValues] = useState({})

  const getValues = async () => {
    const res = props.url ? await axios.get(props.url) : {"data": []}
    let legend_items = {}
    res.data.forEach((f, i) => {
      legend_items[f.id] = {name: f.name, color: chartColors[i]}
    })

    setValues(legend_items)
  }

  useEffect(() => {
    getValues()
  }, [])

  const renderLegend = () => {
    return (
      <Legend className="leaflet-control">
        { Object.keys(values).map(key =>
            <div key={key}><LegendColorBox style={{backgroundColor: values[key].color}}></LegendColorBox>&nbsp;{values[key].name}</div>
          )
        }
      </Legend>
    )
  }

  const singleColorClusters = (cluster) => {
    return (
      L.divIcon({ 
        html: '<div><span><b>' + cluster.getChildCount() + '</b></span></div>', 
        className: 'marker-cluster marker-cluster-medium', 
        iconSize: new L.Point(40,40) })
    )
  }

  const renderIcon = (id) => {
    const pinHtml = values[id] ? '<i class="fa fa-map-pin fa-3x" style="color:' + values[id].color + '"></i>' : '<i class="fa fa-map-pin fa-3x"></i>'
    return (
        L.divIcon({
          html: pinHtml,
          className: ''
      }) 
    )
  }

  const renderPins = () => {
    return (
      props.data.map(v =>  {
        if (v.longitude && v.latitude && v.longitude != "0.0" && v.latitude != "0.0") {
          return (
            <Marker
              position={[parseFloat(v.latitude), parseFloat(v.longitude)]}
              key={v.id}
              icon={renderIcon(v[props.filter_id])}>
              <Popup>{props.markerContent(v)}</Popup>
            </Marker>
          )
        }
      })
    )
  }

  const renderClusters = () => {
    return (
      props.show_clusters ?
        <MarkerClusterGroup key={Math.random()} iconCreateFunction={singleColorClusters} showCoverageOnHover={false}>
          { renderPins()}
        </MarkerClusterGroup>
      : <>{ renderPins()}</>
    )
  }
  
  return (
    <StyledMapContainer center={centralAfrica} zoom={5} scrollWheelZoom={true}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {renderClusters()}
      {renderLegend()}
    </StyledMapContainer>
  )
}

