import React from 'react'
import styled from 'styled-components'
import { BiAlarm, BiAlarmExclamation } from 'react-icons/bi'
import moment from 'moment'

export const chartColors = ["#8CFDB4","#ECAFB5","#89C7FF","#301171","#1A8413","#899214","#4559C7","#A60B46",
    "#E17840","#25802B","#26767E","#DCCF49","#F4DC04",  "#3AA4E4","#26B52F","#F68EA8","#D10D79","#86C5DA","#627C9B",
    "#CFE7A1","#6A7D3C","#A441D5","#DBC7FF",'#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', 
    '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', 
    '#000075', '#808080',]

export const appConstants = {
  MONITORING_EVENT_URL: '/tpm/monitoring_requests/',
  PLANNING_URL: '/tpm/planning.json',
  ARCHIVE_URL: '/tpm/planning/archive.json',
  RESULTS_URL: '/tpm/results.json',
  CALENDAR_URL: '/tpm/events_calendar/',
  REVIEW_BOARD_URL: '/tpm/visits',
  USERS_URL: '/users.json',
  MONITORS_URL: '/users.json?job=monitor',
  REVIEWERS_URL: '/users.json?job=reviewer',
  EVENT_STATUSES_URL: '/tpm/request_statuses',
  ARCHIVE_STATUSES_URL: '/tpm/request_statuses?archive=true',
  IFM_STATUSES_URL: '/tpm/request_statuses?ifm=true',
  ACTIVITIES_URL: '/tpm/activities.json',
  IPS_BY_ACTIVITY_URL: '/tpm/ips_by_activity/',
  IPS_URL: '/tpm/implementing_partners.json',
  ACTIVITIES_BY_IP_URL: '/tpm/activities_by_ip/',
  GOVERNORATES_URL: '/tpm/governorates.json',
  DISTRICTS_BY_GOVERNORATE_URL: '/tpm/districts_by_governorate/',
  TERRITORIES_BY_DISTRICT_URL: '/tpm/territories_by_district/',
  ZONES_BY_TERRITORY_URL: '/tpm/zones_by_territory/',
  VERIFICATIONS_FOR_MONITORING_REQUEST_URL: '/tpm/verifications_for_monitoring_request/',
  VERIFICATION_IMAGES_URL: '/tpm/verifications/images/',
  RESOURCES_FOR_MONITORING_REQUEST_URL: '/kmp/resources_for_monitoring_request/',
  IMAGES_FOR_MONITORING_REQUEST_URL: '/kmp/images_for_monitoring_request/',
  FILTERED_RESOURCES_URL: '/kmp/filtered_resources',
  JUSTIFICATIONS_URL: '/tpm/request_justifications',
  TIME_INTERVALS_URL: '/tpm/time_intervals',
  COLLECTIONS_URL: '/kmp/collections/',
  RESOURCES_URL: '/kmp/resources/',
  SEARCH_URL: '/kmp/search/',
  COLLECTIONS_JSON_URL: '/kmp/collections.json',
  RESOURCES_JSON_URL: '/kmp/resources.json',
  GROUPED_TAGS_URL: '/kmp/grouped_tags/',
  MONITORING_TAGS_URL: '/kmp/grouped_tags?monitoring_request_tags=true',
  REQUESTS_FOR_RESOURCES_URL: '/tpm/requests_for_resources/',
  IMPORT_EVENTS_URL: '/tpm/bulk_import',
  PROJECT_YEARS_URL: '/tpm/project_years.json',
  NEW_RESOURCE_URL: '/kmp/resources/new',
  NEW_COLLECTION_URL: '/kmp/collections/new',
  REPORT_STATUSES_URL: '/tpm/report_statuses',
}

export const setRequest = (e) => {
  const request = {
    id: parseInt(e.id),
    name: e.name,
    request_status: e.request_status_id,
    request_justification_name: e.request_justification_name,
    request_justification: e.request_justification,
    start_date: e.start_date,
    end_date: e.end_date,
    date_requested: e.date_requested,
    date_submitted: e.date_submitted,
    user_id: e.user_id,
    reviewer_id: e.reviewer_id,
    activity_id: e.activity_id,
    activity_name: e.activity_name,
    implementing_partner_id: e.implementing_partner_id,
    implementing_partner_name: e.implementing_partner_name,
    governorate_id: e.governorate_id,
    governorate_name: e.governorate_name,
    district_id: e.district_id,
    territory_id: e.territory_id,
    zone_id: e.zone_id,
    district_name: e.district_name,
    hold: e.hold,
    latitude: e.latitude,
    longitude: e.longitude,
    start_week: e.start_week,
    sites_visited: e.sites_visited,
    site_count: e.site_count,
    report_status: e.report_status,
    tags: e.tags,
    data: {
      poc: e.poc,
      description: e.description,
      beneficiaries: e.beneficiaries,
      request_justification_details: e.request_justification_details,
    }
  }
  return request
}

export const setResource = (e) => {
  const resource = {
    id: parseInt(e.id),
    name: e.name,
    url: e.url,
    description: e.description,
  }
  return resource
}

const GreenTimeDot = styled(BiAlarm)`
  color: green;
  `;

const YellowTimeDot = styled(BiAlarm)`
  color: gold;
  `;

const OrangeTimeDot = styled(BiAlarm)`
  color: orange;
  `;

const RedTimeDot = styled(BiAlarmExclamation)`
  color: red;
  `;

export const renderTimeDots = (endDate, status) => {
  
  let dayDiff = 0
  const now = moment()
  if (endDate && status != 'submitted') {
    const end = moment(endDate)
    dayDiff = now.diff(end, 'days')
  }

  let dots = [];
  if (dayDiff <= 7) {
    _.times(1, (i) => {
      dots.push(<GreenTimeDot key={i}></GreenTimeDot>)
    })
  } else if (dayDiff > 7 && dayDiff <= 14) {
    _.times(2, (i) => {
      dots.push(<GreenTimeDot key={i}></GreenTimeDot>)
    })
  } else if (dayDiff > 14 && dayDiff <= 21) {
    _.times(3, (i) => {
      dots.push(<YellowTimeDot key={i}></YellowTimeDot>)
    })
  } else if (dayDiff > 21 && dayDiff <= 28) {
    _.times(4, (i) => {
      dots.push(<OrangeTimeDot key={i}></OrangeTimeDot>)
    })
  } else if (dayDiff > 28) {
    _.times(5, (i) => {
      dots.push(<RedTimeDot key={i}></RedTimeDot>)
    })
  }
  
  return (
    <>
    {dots}
    </>
  )
}

export const dateFields = {
  requested: "date_requested",
  selected: "date_selected",
  review: "date_in_review",
  second_review: "date_in_second_review",
  submitted: "date_submitted",
}

export const taskHeight = 16.5;