import React from 'react'
import I18n from 'i18n-js/locales.js'
import { appConstants } from 'utils/constants.js'
import MonitoringRequestInfo from './details/MonitoringRequestInfo.js'
import Gallery from './Gallery.js'
import SimpleList from './lists/SimpleList.js'
import { Container, Row, Col, Accordion, Card, Button } from 'react-bootstrap'

export default function MonitoringRequestDetails(props) {
  return (
    <Container>
        <Col>
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  {I18n.t('activerecord.attributes.monitoring_request.details')}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <MonitoringRequestInfo
                        monitoringRequestId={props.monitoringRequest.id} />
                    </Col>
                    <Col md={6}>
                      <Gallery
                        url={appConstants.IMAGES_FOR_MONITORING_REQUEST_URL + String(props.monitoringRequest.id)}
                        title={I18n.t('activerecord.attributes.monitoring_request.site_visit_images')} />
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  {I18n.t('activerecord.attributes.monitoring_request.verifications')}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <SimpleList
                    url={appConstants.VERIFICATIONS_FOR_MONITORING_REQUEST_URL + String(props.monitoringRequest.id)}
                    title={I18n.t('activerecord.attributes.monitoring_request.verifications')} />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                  {I18n.t('activerecord.attributes.monitoring_request.resources')}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <SimpleList
                    url={appConstants.RESOURCES_FOR_MONITORING_REQUEST_URL + String(props.monitoringRequest.id)}
                    title={I18n.t('activerecord.attributes.monitoring_request.resources')} />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
    </Container>
  )
}
