import React, { useEffect, useState } from 'react'
import I18n from 'i18n-js/locales.js'
import { appConstants } from 'utils/constants.js'
import moment from 'moment'
import { Container, Row, Col } from 'react-bootstrap'
import axios from 'axios'

export default function MonitoringRequestInfo(props) {
  const [request, setRequest] = useState([])

  const loadRequest = async() => {
    const res = await axios.get(`${appConstants.MONITORING_EVENT_URL}${props.monitoringRequestId}.json`)
    setRequest(res.data)
  }

  useEffect(() => {
    loadRequest()
  }, [])

  const getRequest = () => {
    return request
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.name')}: </strong>
          {request.name}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.activity')}: </strong>
          {request.activity ? request.activity.name : ""}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.implementing_partner')}: </strong>
          {request.implementing_partner ? request.implementing_partner.name : ""}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.governorate')}: </strong>
          {request.governorate ? request.governorate.name : ""}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.district')}: </strong>
          {request.district ? request.district.name : ""}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.territory')}: </strong>
          {request.territory ? request.territory.name : ""}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.zone')}: </strong>
          {request.zone ? request.zone.name : ""}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.resource.tags')}: </strong>
          {request.tags ? request.tags.map(t => t.name) : ""}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.start_date')}: </strong>
          {request.start_date ? moment(request.start_date).format('MM-DD-YYYY') : ""}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.request_justification')}: </strong>
          {request.request_justification ? I18n.t(`activerecord.attributes.monitoring_request/request_justification.${request.request_justification}`) : ""}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.request_justification_details')}: </strong>
          {request.request_justification_details}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.latitude')}: </strong>
          {request.latitude}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.longitude')}: </strong>
          {request.longitude}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.description')}: </strong>
          {request.data ? request.data.description : ""}</p>
        </Col>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.poc')}: </strong>
          {request.data ? request.data.poc : ""}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p><strong>{I18n.t('activerecord.attributes.monitoring_request.security_hold')}: </strong>
          {request.hold ? 'Yes' : 'No'}</p>
        </Col>
      </Row>
    </Container>
  )
}
